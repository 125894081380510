import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Flex, Row, Space, Col, Tabs, TabsProps, Select, Typography, message, Breadcrumb, Input, Button, Divider } from 'antd';
import OvercastIcon from "../../assets/icons/overcast.svg";
import SpotifyIcon from "../../assets/icons/spotify.svg";
import AppleIcon from "../../assets/icons/apple.svg";
import PocketIcon from "../../assets/icons/pocket.svg";
import AndroidIcon from "../../assets/icons/android.svg";
import QRIcon from "../../assets/QR.svg";
import "./index.css";

const { Title, Link, Text } = Typography;

const AppCard = ({icon, label, onClick, selected}: {icon: string, label: string, onClick: () => void, selected?: boolean}) => {
  return (
    <Flex align='center' className={`app-card ${selected && 'selected'}`} gap={8} onClick={onClick}>
      <img src={icon} alt='app' />
      <Text>{label}</Text>
    </Flex>
  )
}

const appList = [
  {
    icon: OvercastIcon,
    label: 'Overcast'
  },
  {
    icon: SpotifyIcon,
    label: 'Spotify'
  },
  {
    icon: AppleIcon,
    label: 'Apple Podcast'
  },
  {
    icon: PocketIcon,
    label: 'Pocket Casts'
  },
  {
    icon: AndroidIcon,
    label: 'Android'
  },
]

const ProfilePage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [selectedApp, setSelectedApp] = useState<string>('Overcast');

  const handleAppSelect = (value: string) => {
    setSelectedApp(value);
  }

  return (
    <Row className='w-full flex-col'>
      <Flex className='flex-col w-full container bg-white' gap={8}>
        <Title level={4} style={{ marginBottom: '0' }}>Account</Title>
      </Flex>
      <Row className='container detail-header' style={{ marginLeft: 0, marginRight: 0 }} gutter={24}>
        <Space className='bg-white w-full flex-col' style={{ borderRadius: 8 }}>
          <Flex vertical style={{ padding: isMobile ? '1rem' : '1.5rem', width: '100%' }} gap={16}>
            <Title level={5}>Personal Information</Title>
            <Row gutter={[24, 16]}>
              <Col xs={24} sm={12}>
                <Flex vertical className='w-full'>
                  <Title level={5}>Name</Title>
                  <Input value={name} style={{ padding: '4px 12px', borderRadius: '2px' }} onChange={(e) => setName(e.target.value)} />
                </Flex>
              </Col>
              <Col xs={24} sm={12}>
                <Flex vertical className='w-full'>
                  <Title level={5}>Email</Title>
                  <Input value={email} style={{ padding: '4px 12px', borderRadius: '2px' }} onChange={(e) => setEmail(e.target.value)} />
                </Flex>
              </Col>
            </Row>
            <Button type='primary' style={{ width: 'fit-content' }}>Update Information</Button>
          </Flex>
          <Divider style={{ margin: 0 }} />
          <Row gutter={[24, 16]}>
            <Col xs={24} sm={12}>
              <Flex vertical style={{ padding: isMobile ? '1rem' : '1.5rem', width: '100%' }} gap={16}>
                <Title level={5}>Where do you want to receive podcasts?</Title>
                <Flex vertical className='w-full'>
                  <Title level={5}>Email</Title>
                  <Input value={email} style={{ padding: '4px 12px', borderRadius: '2px' }} onChange={(e) => setEmail(e.target.value)} />
                </Flex>
                <Flex vertical className='w-full'>
                  <Title level={5}>RSS</Title>
                  <Flex align='center'>
                    <Input value={email} style={{ padding: '4px 12px', borderRadius: '2px', marginRight: '1rem' }} onChange={(e) => setEmail(e.target.value)} />
                    <Link href='' style={{ whiteSpace: 'nowrap' }}>Copy Link</Link>
                  </Flex>
                </Flex>
                <Flex vertical className='w-full'>
                  <Title level={5}>Podcast</Title>
                  <Flex align='center'>
                    <Input value={email} style={{ padding: '4px 12px', borderRadius: '2px', marginRight: '1rem' }} onChange={(e) => setEmail(e.target.value)} />
                    <Link href='' style={{ whiteSpace: 'nowrap' }}>Copy Link</Link>
                  </Flex>
                </Flex>
              </Flex>
            </Col>
            <Col xs={24} sm={12}>
              <Flex vertical style={{ padding: isMobile ? '1rem' : '1.5rem', width: '100%' }} gap={16}>
                <Title level={5}>Get in the app</Title>
                <Flex vertical className='w-full'>
                  <Title level={5}>Select App</Title>
                  <Row gutter={[{ xs: 16, md: 24 }, 16]} className='w-full' style={{ marginLeft: 0, marginRight: 0 }}>
                    {appList.map((app, index) => (
                      <Col xs={12} xl={8} key={index}>
                        <AppCard icon={app.icon} label={app.label} onClick={() => handleAppSelect(app.label)} selected={selectedApp === app.label} />
                      </Col>
                    ))}
                  </Row>
                </Flex>
                <Flex gap={16} align='center' style={{ marginTop: '1rem' }}>
                  <img src={QRIcon} alt='QR code' />
                  <Flex vertical>
                    <Title level={5}>Scan this QR code with your phone</Title>
                    <Link href='' style={{ whiteSpace: 'nowrap' }}>Open Link</Link>
                  </Flex>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Space>
      </Row>
    </Row>
  );
};

export default ProfilePage;