import { ReactNode } from 'react';
import { Flex, Spin } from 'antd';

const Spinner = ({loading, children}: {loading: boolean, children: ReactNode}) => {
  if (loading) {
    return (
      <Flex className='w-full flex container justify-center' style={{ paddingTop: '3rem' }}>
        <Spin size='large' />
      </Flex>
    )
  } else {
    return <>{children}</>;
  }
};

export default Spinner;
