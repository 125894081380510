import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const Page404: React.FC = () => {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate('/')
  }

  return (
    <Flex justify='center' align='center' style={{ height: '100vh' }}>
      <Result
        icon={<SmileOutlined style={{ fontSize: '48px' }} />}
        status="404"
        title="404: Page Not Found"
        extra={
          <Button type="primary" onClick={handleReturn}>
            Return
          </Button>
        }
      />
    </Flex>
  );
};

export default Page404;