import { Paragraph } from "../data/interfaces/document";

export const getParagraphs = (text: string) => {
  return text.split('\n').filter(p => p.trim() !== '').map((p, index) => {
    const newParagraph : Paragraph = {
      id: index,
      text: p,
    }

    return newParagraph;
  })
}

export const getPartOfSpeech = (pos: string) => {
  switch (pos) {
    case 'noun': return 'n';
    case 'pronoun': return 'pron';
    case 'verb': return 'v';
    case 'adjective': return 'adj';
    case 'adverb': return 'adv';
    case 'preposition': return 'prep';
    case 'conjunction': return 'conj';
    case 'particle': return 'part';
    case 'punctuation': return 'punc';
    case 'proper noun': return 'pn';
    case 'numeral': return 'num';
    case 'auxiliary': return 'aux';
    case 'question adverb': return 'qadv';
    case 'prefix': return 'pref';
    case 'suffix': return 'suf';
    case 'auxiliary verb': return 'auxv';
    case 'quantifier': return 'quant';
    case 'measure word': return 'mw';
    case 'quantifier phrase': return 'qp';
    case 'adjective phrase': return 'adjp';
    case 'postposition': return 'postp';
    case 'numerical phrase': return 'nump';
    case 'interjection': return 'interj';
    case 'complement': return 'comp';
  
    default:
      break;
  }
}
