import { useState } from 'react';
import { Button, Flex, Modal, Typography, Input, Checkbox, Space, notification, message, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { AddNewTextDto, MaterialOptions, Status } from '../../data/interfaces/document';
import { addNewText } from '../../utils/supabase';
import axiosInstance from '../../utils/axiosInstance';

const { TextArea } = Input;
const { Text, Title } = Typography;

const DocumentAddModal = ({open, setOpen, callback}: {open: boolean, setOpen: (value: boolean) => void, callback: (id: string) => Promise<void>}) => {
  const [step, setStep] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForTitle, setLoadingForTitle] = useState<boolean>(false);
  const [materialOptions, setMaterialOptions] = useState<MaterialOptions>({
    audio: true,
    exercises: true,
    translation: true,
    wordsAndPhrases: true,
    pinyinAndDefinitions: true,
  })
  const [api, contextHolder] = notification.useNotification();
  
  const handleCheckboxChange = (e: CheckboxChangeEvent, option: string) => {
    e.stopPropagation();
    setMaterialOptions((prev) => ({
      ...prev,
      [option]: e.target.checked,
    }))
  }

  const handleSubmit = async () => {
    try {
      const newStatus: Status = {
        audio: materialOptions.audio ? 'needed' : 'not_needed',
        exercises: materialOptions.exercises ? 'needed' : 'not_needed',
        translation: materialOptions.translation ? 'needed' : 'not_needed',
        wordsAndPhrases: materialOptions.wordsAndPhrases ? 'needed' : 'not_needed',
        pinyinAndDefinitions: materialOptions.pinyinAndDefinitions ? 'needed' : 'not_needed',
      }
      const newDocumentDto: AddNewTextDto = {
        title: title,
        text: text,
        status: newStatus,
      }
  
      setLoading(true);
      const response = await addNewText(newDocumentDto);
      setLoading(false);
      setOpen(false);
      setTitle('');
      setStep(1);
      setText('');
      setMaterialOptions({
        audio: true,
        exercises: true,
        translation: true,
        wordsAndPhrases: true,
        pinyinAndDefinitions: true,
      })
      if (response.length > 0) {
        callback(response[0].id);
      }
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  const handleCancel = () => {
    setOpen(false);
    setTitle('');
    setStep(1);
    setText('');
    setMaterialOptions({
      audio: true,
      exercises: true,
      translation: true,
      wordsAndPhrases: true,
      pinyinAndDefinitions: true,
    })
  }

  const handleNextStep = async () => {
    try {
      setLoadingForTitle(true);
      setStep(2);
      const response = await Promise.race([
        axiosInstance.post('/api/suggest-title', { text: text.slice(0, 400) }),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 5000))
      ])

      if (response && typeof response === 'object' && 'data' in response) {
        setTitle(response.data as string);
      }
    } catch (error) {
      console.log((error as Error).message);
    }
    setLoadingForTitle(false);
  }

  const modalTitle = () => {
    switch (step) {
      case 1:
        return 'New document'
    
      case 2:
        return 'Document settings'
    
      default:
        break;
    }
  }

  const footer = () => {
    if (step === 1) {
      return (
        <Flex className='w-full'>
          <Button key='cancel' onClick={handleCancel} style={{ borderRadius: 2 }}>Cancel</Button>
          <Button key='continue' type='primary' disabled={!text} style={{ borderRadius: 2, marginLeft: 'auto' }} onClick={handleNextStep}>Continue</Button>
        </Flex>
      )
    } else {
      return (
        <Flex className='w-full'>
          <Button key='cancel' onClick={handleCancel} style={{ borderRadius: 2 }}>Cancel</Button>
          <Button key='back' icon={<ArrowLeftOutlined />} onClick={() => setStep(1)} style={{ borderRadius: 2, marginLeft: 'auto', marginRight: '1rem' }}>Back</Button>
          <Button key='submit' type='primary' loading={loading} disabled={!title} onClick={handleSubmit} style={{ borderRadius: 2 }}>Save</Button>
        </Flex>
      )
    }
  }

  const body = () => {
    if (step === 1) {
      return (<Flex vertical gap={16} style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '1rem 0' }}>
        <Text>Enter chinese text and we will generate translations, flashcards & exercises for you</Text>
        <TextArea rows={16} placeholder='Paste text here' value={text} onChange={(e) => setText(e.target.value)} style={{ borderRadius: 2 }} />
      </Flex>)
    } else {
      return (<Flex vertical gap={24} style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '1rem 0' }}>
        {loadingForTitle ? (
          <Spin style={{ margin: '5rem 0' }} />
        ) : (
          <>
            <Text>You can change auto generated title and description if you need</Text>
            <Flex vertical>
              <Title level={5}><span style={{ color: 'red', marginRight: '.2rem' }}>*</span>Title</Title>
              <Input value={title} onChange={(e) => setTitle(e.target.value)} style={{ borderRadius: 2 }} required />
            </Flex>
            <Flex vertical gap={8}>
              <Title level={5} style={{ marginBottom: 0 }}>Choose additional materials to generate</Title>
              <Text>AI will generate study materials in up to 10 minutes. Select options:</Text>
              <Checkbox checked={materialOptions.translation} onChange={(e) => handleCheckboxChange(e, 'translation')} style={{ borderRadius: 2 }}>Document translation</Checkbox>
              <Checkbox checked={materialOptions.pinyinAndDefinitions} onChange={(e) => handleCheckboxChange(e, 'pinyinAndDefinitions')} style={{ borderRadius: 2 }}>Word definitions and pinyin</Checkbox>
              <Checkbox checked={materialOptions.audio} onChange={(e) => handleCheckboxChange(e, 'audio')} style={{ borderRadius: 2 }}>Audio narration</Checkbox>
              <Checkbox checked={materialOptions.wordsAndPhrases} onChange={(e) => handleCheckboxChange(e, 'wordsAndPhrases')} style={{ borderRadius: 2 }}>Key words & phrases</Checkbox>
              <Checkbox checked={materialOptions.exercises} onChange={(e) => handleCheckboxChange(e, 'exercises')} style={{ borderRadius: 2 }}>Practice exercises</Checkbox>
            </Flex>
          </>
        )}
      </Flex>)
    }
  }

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      {contextHolder}
      <Modal
        open={open}
        title={modalTitle()}
        onCancel={handleCancel}
        footer={footer()}
        centered
      >
        {body()}
      </Modal>
    </Space>
  );
};

export default DocumentAddModal;