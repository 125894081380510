import styled from 'styled-components';
import { Card, Button, Flex, Row } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { ExerciseInterface } from '../../data/interfaces/exercise';

const StyledCard = styled(Card)`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;

  &:hover {
    background: #F5F5F5;
  }
`

const ExerciseCard = ({exercise, goToDetail}: {exercise?: ExerciseInterface, goToDetail: (doc: ExerciseInterface) => void}) => {
  if (!exercise) {
    return null;
  }

  return (
    <StyledCard
      styles={{ body: { gap: '.5rem', display: 'flex', padding: '1rem 1.5rem', flexDirection: 'column' } }}
      onClick={() => goToDetail(exercise)}
    >
      <Row style={{ gap: 8 }}>
        <Flex className='w-full' justify='space-between'>
          <Title level={4} style={{ marginBottom: 0 }}>{exercise?.title}</Title>
          <Button icon={<ArrowRightOutlined />} style={{ border: 'none', boxShadow: 'none', background: 'transparent' }} onClick={() => exercise && goToDetail(exercise)} />
        </Flex>
        <Title level={5} style={{ marginBottom: 0 }}>{exercise?.description}</Title>
      </Row>
    </StyledCard>
  );
};

export default ExerciseCard;