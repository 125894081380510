import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, Collapse, CollapseProps, Flex, message, Row, Space, Typography } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import FlashCard from '../../../components/flashcards/FlashCard';
import ExportModal from '../../../components/flashcards/ExportModal';
import FlashcardDeleteModal from '../../../components/flashcards/FlashcardDeleteModal';
import Spinner from '../../../components/layout/Spinner';
import { DocumentDto } from '../../../data/interfaces/document';
import { DataTypeForCardTable } from '../../../data/interfaces/flashcard';
import { editText, getUserTexts } from '../../../utils/supabase';

const { Title, Text } = Typography;

const FlashcardsPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [items, setItems] = useState<CollapseProps['items']>([]);
  const [documents, setDocuments] = useState<DocumentDto[]>([]);
  const [selectedWord, setSelectedWord] = useState<DataTypeForCardTable>();
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [openDeleteWordModal, setOpenDeleteWordModal] = useState<boolean>(false);
  const [loadingForDelete, setLoadingForDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchData();
  }, [])
  
  const fetchData = async () => {
    try {
      const response: DocumentDto[] = await getUserTexts();
      if (response) {
        setDocuments(response);
        const temp: CollapseProps['items'] = [];
        response.map((doc) => {
          const flashcards = doc.flashcards;
          if (flashcards && flashcards.length > 0) {
            temp.push({
              key: doc.id,
              label: doc.title,
              extra: <Text>{flashcards.length} word{flashcards.length > 1 ? 's' : ''}</Text>,
              children: <FlashCard doc={doc} words={flashcards} handleCardDelete={handleCardDelete} />
            })
          }
        })
        setItems(temp);
      }
    } catch (error) {
      message.error((error as Error).message)
    }
    setLoading(false);
  };

  const handleCardDelete = (value: DataTypeForCardTable) => {
    setSelectedWord(value);
    setOpenDeleteWordModal(true);
  }

  const handleDeleteSubmit = async () => {
    try {
      if (selectedWord) {
        const selectedDocument = documents.find(d => d.id === selectedWord?.doc_id);

        if (selectedDocument && selectedDocument.flashcards) {
          selectedDocument.flashcards = selectedDocument.flashcards.filter(f => f.id !== selectedWord.id);
          setLoadingForDelete(true);
          await editText(selectedDocument, selectedDocument.id);
          setLoadingForDelete(false);
          setOpenDeleteWordModal(false);
          fetchData();
        }
      }
    } catch (error) {
      message.error((error as Error).message);
      setLoadingForDelete(false);
    }
  }

  return (
    <Row className='w-full'>
      <Flex className='flex bg-white justify-between align-center w-full container'>
        <Title level={4} style={{ marginBottom: '0' }}>Flashcards</Title>
        <Button type='primary' icon={<ExportOutlined />} style={{ borderRadius: 2 }} onClick={() => setOpenExportModal(true)}>Export</Button>
      </Flex>

      <Spinner loading={loading}>
        <Flex className='container'>
          <Space className='bg-white w-full' style={isMobile ? {} : { padding: 24, borderRadius: 8 }}>
            <Collapse items={items} style={{ borderRadius: 2 }} />
          </Space>
        </Flex>
      </Spinner>

      <ExportModal open={openExportModal} setOpen={setOpenExportModal} documents={documents} />
      <FlashcardDeleteModal open={openDeleteWordModal} setOpen={setOpenDeleteWordModal} word={selectedWord} loading={loadingForDelete} handleSubmit={handleDeleteSubmit} />
    </Row>
  );
};

export default FlashcardsPage;