import { Button, Flex, Modal, Typography, Space } from 'antd';
import { DataTypeForCardTable } from '../../data/interfaces/flashcard';

const { Text } = Typography;

const FlashcardDeleteModal = ({word, open, setOpen, loading, handleSubmit}: {word: DataTypeForCardTable | undefined, open: boolean, setOpen: (value: boolean) => void, loading: boolean, handleSubmit: () => void}) => {

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      <Modal
        open={open}
        title={`Delete ${word?.word}?`}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key='cancel' onClick={() => setOpen(false)} style={{ borderRadius: 2 }}>Cancel</Button>,
          <Button key='delete' type='primary' loading={loading} onClick={handleSubmit} style={{ borderRadius: 2 }}>Delete</Button>
        ]}
        centered
      >
        <Flex vertical gap={24} style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '1rem 0' }}>
          <Text>Are you sure you want to delete document? This actions can't be undone</Text>
        </Flex>
      </Modal>
    </Space>
  );
};

export default FlashcardDeleteModal;