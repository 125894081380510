import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, Flex, Input, message, Row, Space, Typography } from 'antd';
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import Spinner from '../../components/layout/Spinner';
import SearchResult from '../../components/dictionary/SearchResult';
import SearchHistory from '../../components/dictionary/SearchHistory';
import { DictionaryDto, GroupedSearchHistory, SearchHistoryDto } from '../../data/interfaces/dictionary';
import { getSearchHistoryResult, getSearchResult } from '../../utils/supabase';
import { formatShortDate } from '../../utils/dictionary';
import "./index.css";

const { Search } = Input;
const { Title, Text } = Typography;

const DictionaryPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [keyword, setKeyword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<DictionaryDto[]>([]);
  const [searchHistory, setSearchHistory] = useState<SearchHistoryDto[]>([]);
  const [groupedSearchHistory, setGroupedSearchHistory] = useState<GroupedSearchHistory[]>([]);

  useEffect(() => {
    if (keyword) {
      setLoading(true);
      fetchData(keyword)
    } else {
      setLoading(true);
      fetchHistoryData();
    }
  }, [keyword]);

  useEffect(() => {
    if (searchHistory.length > 0) {
      const groupedByDate: Record<string, SearchHistoryDto[]> = searchHistory.reduce((acc: Record<string, SearchHistoryDto[]>, item: SearchHistoryDto) => {
        const date = formatShortDate(item.date);

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(item);
        return acc;
      }, {} as Record<string, SearchHistoryDto[]>);

      setGroupedSearchHistory(Object.entries(groupedByDate).map(([date, results]) => ({ date, results })));
    }
  }, [searchHistory])

  const fetchHistoryData = async () => {
    try {
      const response: any = await getSearchHistoryResult();
      if (response?.length > 0 && response[0].lookup_history.reverse()) {
        setSearchHistory(response[0].lookup_history)
      }
    } catch (error) {
      message.error((error as Error).message)
    }
    setLoading(false);
  };

  const fetchData = async (keyword: string) => {
    try {
      const response = await getSearchResult(keyword);
      if (response) {
        setSearchResults(response);
      }
    } catch (error) {
      message.error((error as Error).message)
    }
    setLoading(false);
  };

  const onSearch = (value: string) => {
    setKeyword(value);
  }

  return (
    <Row className='w-full dictionary'>
      <Flex vertical className='bg-white w-full container' gap={8}>
        <Title level={4}>Dictionary</Title>
        <Search 
          placeholder='Type word to search in dictionary' 
          onSearch={onSearch} 
          enterButton={[
            <SearchOutlined />,
            "Search"
          ]}
          size='large'
          style={{ width: isMobile ? '100%' : '400px' }}
          styles={{input: { borderRadius: '2px', height: '34px' }}}
        />
        <Text type='secondary'>Search words with Chinese characters only</Text>
      </Flex>
      <Flex vertical className='container w-full' align='start'>
        {keyword && <Button type='link' icon={<ArrowLeftOutlined />} style={{ marginBottom: '1rem' }} onClick={() => setKeyword('')} >Back to previous results</Button>}
        <Space className='bg-white w-full flex-col search-container'>
          <Title level={5} style={{ paddingBottom: '1rem', marginBottom: 0, borderBottom: '1px solid #DDE1E6', paddingLeft: '16px' }}>{keyword ? 'Search result' : 'Previous search results'}</Title>
          <Spinner loading={loading}>
            {keyword ? 
              <SearchResult searchResult={searchResults} /> :
              <SearchHistory searchHistory={groupedSearchHistory} />
            }
          </Spinner>
        </Space>
      </Flex>
    </Row>
  );
};

export default DictionaryPage;