import { useEffect, useState } from 'react';
import { Flex, Popover, message, Button, Space, Row } from 'antd';
import DictionaryPop from './DictionaryPop';
import TextRow from './TextRow';
import AudioPlayer from '../common/AudioPlayer';
import { DocumentDto, Word } from '../../data/interfaces/document';
import { FlashcardDto } from '../../data/interfaces/flashcard';
import { editText, getAudioUrl, getFlashcards, setBookmark } from '../../utils/supabase';

interface FlashcardsData {
  id: string;
  title: string;
  flashcards: FlashcardDto[];
}

interface RProps {
  doc?: DocumentDto, 
  items: string[], 
  callback: (payload: DocumentDto) => void, 
  bookIndex: number | undefined,
  setBookIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
  refs: React.MutableRefObject<(HTMLDivElement | null)[]>;
}

const TextTabContent = ({ doc, items, callback, refs, bookIndex, setBookIndex }: RProps) => {
  const [selectedWord, setSelectedWord] = useState<Word | null>(null);
  const [selectedText, setSelectedText] = useState<string>('');
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [flashcardsData, setFlashcardsData] = useState<FlashcardsData[]>([]);
  const [audio, setAudio] = useState<string>('');

  useEffect(() => {
    document.addEventListener('mouseup', handleTextSelection);
    document.addEventListener('mousedown', handleClickOutside);

    fetchData();
    setAudioURL();

    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [doc]);

  const fetchData = async () => {
    const response: FlashcardsData[] = await getFlashcards();;
    setFlashcardsData(response);
  }

  const setAudioURL = async () => {
    if (doc?.json_structure?.content?.audio_url) {
      const url = await getAudioUrl(doc?.json_structure?.content?.audio_url)
      if (url) {
        setAudio(url);
      }
    }
  }

  const handleWordClick = (word: Word, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (word.partOfSpeech === 'punctuation') {
      return;
    }
    const rect = event.currentTarget.getBoundingClientRect();

    const popoverLeft = window.innerWidth - rect.left < 200 
      ? Math.max(0, rect.left + window.scrollX - 200) 
      : rect.left + window.scrollX;

    const popoverTop = window.innerHeight - rect.top < 200
      ? Math.max(rect.top + window.scrollY - 200)
      : rect.top + window.scrollY + 20;

    setPopoverPosition({
      top: popoverTop,
      left: popoverLeft,
    });

    setSelectedWord(word);
    setPopoverVisible(true);
  }

  const handleVisibleChange = (open: any) => {
    setPopoverVisible(open)
  }

  const handleClickOutside = () => {
    setPopupVisible(false);
  };

  const handleTextSelection = (e: any) => {
    const selection = window.getSelection();
    
    if (selection) {
      const selectedRange = selection?.getRangeAt(0);
      const selectedTextNodes = Array.from(selectedRange.cloneContents().querySelectorAll('.selectable'));

      if (selectedTextNodes.length > 0) {
        const selText = selectedTextNodes.map(node => node.textContent).join('');
        setSelectedText(selText);

        const range = selection.getRangeAt(0).getBoundingClientRect();
        setPopupPosition({
          left: (range.left + range.right) / 2 - 60,
          top: range.top + window.scrollY - 60
        });
        setPopupVisible(true);
      } else {
        setPopupVisible(false);
      }
    } else {
      setPopupVisible(false);
    }
  }

  const isFlashcard = (word: Word): number => {
    if (doc?.flashcards?.find(f => f.word === word.text)) {
      return 1;
    } else {
      let added = false;
      for (let index = 0; index < flashcardsData.length; index++) {
        const element = flashcardsData[index];
        
        if (element.flashcards.find(f => f.word === word.text)) {
          added = true;
          break;
        }
      }

      if (added) {
        return 2;
      } else {
        return 0;
      }
    }
  }

  const handleFlashcard = async (word: Word, adding: boolean) => {
    try {
      if (doc) {
        if (adding) {
          const newFlashcard: FlashcardDto = {
            word: word.text,
            pinyin: word.pinyin,
            audio_url: word.audio_url,
            part_of_speech: word.partOfSpeech,
            short_definitions: word.translation ? [word.translation] : []
          }
    
          if (doc.flashcards) {
            doc.flashcards?.push(newFlashcard);
          } else {
            doc.flashcards = [newFlashcard];
          }
        } else {
          doc.flashcards = doc.flashcards?.filter(f => f.word !== word.text)
        }

        await editText(doc, doc.id);
        callback(doc);
        setPopoverVisible(false);
      }
    } catch (error) {
      message.error((error as Error).message);
    }
  }

  const handleBookmark = async (index: number) => {
    if (doc) {
      try {
        await setBookmark(doc.id, index);
        setBookIndex(index);
      } catch (error) {
        message.error((error as Error).message);
      }
    }
  }

  return (
    <Flex className='container' gap={16} vertical>
      <Space className='w-full bg-white flex-col' style={{ padding: '1rem 0', borderRadius: 8 }}>
        <Row style={{ margin: '1rem' }}>
          <AudioPlayer url={audio} />
        </Row>
        <Flex vertical gap={16}>
          {doc?.json_structure?.content?.paragraphs.map((p, p_index) => (
            <TextRow
              key={p_index}
              index={p_index}
              ref={(el: any) => (refs.current[p_index] = el)}
              showChinese={items.includes('chinese')}
              showPinyin={items.includes('pinyin')}
              showEnglish={items.includes('english')}
              paragraph={p}
              isBookmarked={p_index === bookIndex}
              handleWordClick={handleWordClick}
              handleBookmark={handleBookmark}
            />
          ))}
        </Flex>
      </Space>

      <Popover
        content={selectedWord && <DictionaryPop word={selectedWord} handleFlashcard={handleFlashcard} isFlashcard={isFlashcard(selectedWord)} />}
        open={popoverVisible}
        trigger={["click"]}
        onOpenChange={handleVisibleChange}
        placement='bottom'
        forceRender={true}
        overlayStyle={{
          position: 'absolute',
          top: `${popoverPosition.top}px`,
          left: `${popoverPosition.left}px`,
          height: 'fit-content',
        }}
        motion={{}}
      />

      <Popover
        content={selectedText && (
          <Flex gap={16}>
            <Button style={{ border: 'none', boxShadow: 'none' }}>Grammar</Button>
            <Button style={{ border: 'none', boxShadow: 'none' }}>Meaning</Button>
          </Flex>
        )}
        open={popupVisible}
        trigger={["click"]}
        onOpenChange={(open) => setPopupVisible(open)}
        placement='top'
        forceRender={true}
        overlayStyle={{
          position: 'absolute',
          top: `${popupPosition.top}px`,
          left: `${popupPosition.left}px`,
          height: 'fit-content',
        }}
        motion={{}}
      />
    </Flex>
  );
};

export default TextTabContent;