import { AddNewTextDto } from "../data/interfaces/document";
import { clearAuth, setAuth } from "../store/authSlice";
import { supabase } from "../supabaseClient";

// Authorization
export const signUp = async (email: string, password: string, dispatch: any) => {
  const { data: { session }, error } = await supabase.auth.signUp({ email, password });

  if (error) {
    throw error;
  }

  dispatch(setAuth(session?.user))
}

export const signIn = async (email: string, password: string, dispatch: any) => {
  const { data: { session }, error } = await supabase.auth.signInWithPassword({ email, password });

  if (error) {
    throw error;
  }

  dispatch(setAuth(session?.user));
}

export const resetPassword = async (email: string, redirectTo: string) => {
  const { error } = await supabase.auth.resetPasswordForEmail(email, { redirectTo: redirectTo });

  if (error) {
    throw error;
  }
}

export const updatePassword = async (password: string) => {
  const { error } = await supabase.auth.updateUser({ password: password });

  if (error) {
    throw error;
  }
}

export const signOut = async (dispatch: any) => {
  const { error } = await supabase.auth.signOut();

  if (error) {
    throw error;
  }

  dispatch(clearAuth());
}

// Document
export const addNewText = async (payload: AddNewTextDto): Promise<{ id: string }[]> => {
  const { data: { user } } = await supabase.auth.getUser();

  if (user) {
    const { data, error } = await supabase.from('documents').insert([payload]).select('id');

    if (error) {
      throw error;
    }

    return data;
  } else {
    return [];
  }
}

export const editText = async (payload: any, id: string) => {
  const { data, error } = await supabase.from('documents').update(payload).eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}

export const getUserTexts = async () => {
  const { data: { user } } = await supabase.auth.getUser();

  if (user) {
    const { data, error } = await supabase.from('documents').select('*').order('created_at', { ascending: false });
  
    if (error) {
      throw error;
    }
    
    return data;
  } else {
    return [];
  }
}

export const getUserTextsForList = async () => {
  const { data: { user } } = await supabase.auth.getUser();

  if (user) {
    const { data, error } = await supabase.from('documents').select('id, status, title, text, isFavorite').order('created_at', { ascending: false });
  
    if (error) {
      throw error;
    }
    
    return data;
  } else {
    return [];
  }
}

export const getTextById = async (id: string) => {
  const { data: { user } } = await supabase.auth.getUser();

  if (user) {
    const { data, error } = await supabase.from('documents').select('*').eq('id', id);
  
    if (error) {
      throw error;
    }
  
    return data;
  } else {
    return [];
  }
}

export const getTextByIdForList = async (id: string) => {
  const { data: { user } } = await supabase.auth.getUser();

  if (user) {
    const { data, error } = await supabase.from('documents').select('id, status, title, text, isFavorite').eq('id', id);
  
    if (error) {
      throw error;
    }
  
    return data;
  } else {
    return [];
  }
}

export const deleteText = async (id: string) => {
  const { data, error } = await supabase.from('documents').delete().eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}

export const setBookmark = async (id: string, pIndex: number) => {
  const { data, error } = await supabase.from('documents').update({bookmark: pIndex}).eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}

export const getFlashcards = async () => {
  const { data: { user } } = await supabase.auth.getUser();

  if (user) {
    const { data, error } = await supabase.from('documents').select('id, title, flashcards').not('flashcards', 'is', null);
  
    if (error) {
      throw error;
    }
    
    return data;
  } else {
    return [];
  }
}

export const getAudioUrl = async (url: string) => {
  const { data: { user } } = await supabase.auth.getUser();

  if (user) {
    const { data, error } = await supabase.storage.from('users').createSignedUrl(`${user.id}/${url}`, 60);

    if (error) {
      return null;
    }
    
    return data.signedUrl;
  } else {
    return null;
  }
}

// Dictionary
export const getSearchResult = async (keyword: string) => {
  const { data: { user } } = await supabase.auth.getUser();

  if (user) {
    const { data, error } = await supabase.from('chinese_dictionary').select('*').eq('simplified', keyword);
  
    if (error) {
      throw error;
    }
  
    await addSearchResult(user.id, data.map((item: any) => {return {id: item.id, simplified: item.simplified, date: new Date() }}))
  
    return data;
  } else {
    return [];
  }
}

export const getSearchHistoryResult = async () => {
  const { data: { user } } = await supabase.auth.getUser();

  if (user) {
    const { data, error } = await supabase.from('dictionary_search_history').select('lookup_history').eq('user_id', user.id);
  
    if (error) {
      throw error;
    }
  
    return data;
  } else {
    return [];
  }
}

export const addSearchResult = async (userId: string, newResult: any[]) => {
  const { data, error } = await supabase.from('dictionary_search_history').select('user_id, lookup_history').eq('user_id', userId).single();
  
  if (data) {
    const updatedHistory = data.lookup_history ? [...data.lookup_history, ...newResult] : [...newResult];

    await supabase.from('dictionary_search_history').update({ lookup_history: updatedHistory }).eq('user_id', userId);
  } else {
    await supabase.from('dictionary_search_history').insert({ user_id: userId, lookup_history: [...newResult] })
  }
}

export const getWordDictionaryById = async (id: number) => {
  const { data, error } = await supabase.from('chinese_dictionary').select('*').eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}
