import { useMediaQuery } from "react-responsive";
import { Flex, Table, TableColumnsType, Typography } from "antd";
import { DeleteOutlined, SoundOutlined } from "@ant-design/icons";
import { DataTypeForCardTable, FlashcardDto } from "../../data/interfaces/flashcard";
import { DocumentDto } from "../../data/interfaces/document";

const { Text } = Typography;


const FlashCard = ({words, doc, handleCardDelete, selectable, getRowSelection,}: {words: FlashcardDto[], doc: DocumentDto, handleCardDelete?: (value: DataTypeForCardTable) => void, selectable?: boolean, getRowSelection?: (key: string) => object}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const columns: TableColumnsType<DataTypeForCardTable> = selectable ? [
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
      render: (text) => (
        <Flex align="center">
          <a style={{ color: '#1890FF' }}>{text}</a>
          <SoundOutlined style={{ marginLeft: '1rem', color: '#1890FF' }} />
        </Flex>
      ),
      width: 150
    },
    {
      title: 'Pinyin',
      dataIndex: 'pinyin',
      key: 'pinyin',
      width: 150
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
      width: 550
    },
  ] : [
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
      render: (text) => (
        <Flex align="center">
          <a style={{ color: '#1890FF' }}>{text}</a>
          <SoundOutlined style={{ marginLeft: '1rem', color: '#1890FF' }} />
        </Flex>
      ),
      width: 150
    },
    {
      title: 'Pinyin',
      dataIndex: 'pinyin',
      key: 'pinyin',
      width: 150
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (_, record) => (
        <DeleteOutlined 
          style={{ color: '#1890FF', alignSelf: 'end' }} 
          onClick={handleCardDelete ? () => handleCardDelete(record) : undefined} 
        />
      )
    }
  ]
  
  const data = words.map((w) => {
    return {
      key: w.word,
      word: w.word,
      pinyin: w.pinyin,
      definition: w.short_definitions?.join(' '),
      id: w.id,
      doc_id: doc.id
    }
  })

  return (
    <Flex className="w-full" align="center">
      {!isMobile ? selectable ? getRowSelection && (
        <Table<DataTypeForCardTable> 
          rowSelection={{ type: 'checkbox', ...getRowSelection(doc.id) }}
          columns={columns} 
          dataSource={data} 
          showHeader={false} 
          pagination={false} 
          bordered
          style={{ width: '100%' }}
          size='small' 
        />
      ) : (
        <Table<DataTypeForCardTable> 
          columns={columns} 
          dataSource={data} 
          showHeader={false} 
          pagination={false} 
          bordered
          style={{ width: '100%' }}
          size='small' 
        />
      ) : (
        <Flex vertical className="w-full">
          {words.map((w, index) => (
            <Flex vertical key={index} gap={8} style={{ padding: 16, border: '1px solid #D9D9D9' }}>
              <Flex align="center" justify="space-between">
                <Flex align="center" gap={8}>
                  <Text style={{ color: '#1890FF' }}>{w.word}</Text>
                  <Text>{w.pinyin}</Text>
                  <SoundOutlined style={{ color: '#1890FF' }} />
                </Flex>
                <DeleteOutlined style={{ color: '#1890FF' }} />
              </Flex>
              <Text>{w.short_definitions?.join(' ')}</Text>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export default FlashCard;