import { WordDefinition } from "./interfaces/dictionary";
import { PhraseDetail, WordDictionaryData } from "./interfaces/document";
import { ExerciseInterface } from "./interfaces/exercise";
import { WordFlashcard } from "./interfaces/flashcard";

export const wordData: WordDictionaryData = {
  word: '假文',
  pronunciation: 'jiǎwén',
  definitions: [
    '1. to heat',
    '2. to add warmth',
    '3. to raise temperature',
    '4. fig. to stimulate',
  ],
}

export const exercisesData: ExerciseInterface[] = [
  {
    id: 1,
    doc_id: '65b954b0-46fc-41b6-b1e7-be160bc0eed6',
    title: 'Exercise #1',
    description: 'Document description'
  },
  {
    id: 2,
    doc_id: '2',
    title: 'Exercise #2',
    description: 'Document description'
  },
  {
    id: 3,
    doc_id: '65b954b0-46fc-41b6-b1e7-be160bc0eed6',
    title: 'Exercise #3',
    description: 'Document description'
  },
  {
    id: 4,
    doc_id: '65b954b0-46fc-41b6-b1e7-be160bc0eed6',
    title: 'Exercise #4',
    description: 'Document description'
  },
  {
    id: 5,
    doc_id: '2',
    title: 'Exercise #5',
    description: 'Document description'
  },
  {
    id: 6,
    doc_id: '65b954b0-46fc-41b6-b1e7-be160bc0eed6',
    title: 'Exercise #6',
    description: 'Document description'
  },
  {
    id: 7,
    doc_id: '3',
    title: 'Exercise #7',
    description: 'Document description'
  },
]

export const searchHistoryData: WordDefinition[] = [
  {
    id: 3244,
    word: '假文',
    pinyin: 'jiǎwén',
    audio: 'asd',
    definitions: [
      '1. to heat',
      '2. to add warmth',
      '3. to raise temperature',
      '4. fig. to stimulate',
    ],
  },
  {
    id: 2342,
    word: '假文',
    pinyin: 'jiǎwén',
    audio: 'asd',
    definitions: [
      '1. to heat',
      '2. to add warmth',
      '3. to raise temperature',
      '4. fig. to stimulate',
    ],
  },
  {
    id: 2414,
    word: '假文',
    pinyin: 'jiǎwén',
    audio: 'asd',
    definitions: [
      '1. to heat',
      '2. to add warmth',
      '3. to raise temperature',
      '4. fig. to stimulate',
    ],
  },
]

export const searchResultData: WordDefinition[] = [
  {
    id: 3424,
    word: '元',
    pinyin: 'yuán',
    audio: 'asd',
    definitions: [
      '1. (key) element; essential factor.',
      '2. unit;component',
    ],
  },
  {
    id: 4243,
    word: '元',
    pinyin: 'Yuán',
    audio: 'asd',
    definitions: [
      '1. surname Yuan,',
      '2. the Yuan ot Mongol dynsty',
    ],
  },
]

export const flashcardsData: WordFlashcard[] = [
  {
    id: 3424,
    doc_id: 'e822538f-fdc3-4682-9b95-479fbc703456',
    word: '元',
    pinyin: 'yuán',
    audio: 'asd',
    definitions: [
      '1. (key) element; essential factor.',
      '2. unit;component',
    ],
  },
  {
    id: 3244,
    doc_id: 'a48cbff2-aa88-42d1-9e1b-7efba931a5f1',
    word: '假文',
    pinyin: 'jiǎwén',
    audio: 'asd',
    definitions: [
      '1. to heat',
      '2. to add warmth',
      '3. to raise temperature',
      '4. fig. to stimulate',
    ],
  },
  {
    id: 4243,
    doc_id: 'e822538f-fdc3-4682-9b95-479fbc703456',
    word: '假文元',
    pinyin: 'Yuán',
    audio: 'asd',
    definitions: [
      '1. surname Yuan,',
      '2. the Yuan ot Mongol dynsty',
    ],
  },
  {
    id: 4247,
    doc_id: 'e822538f-fdc3-4682-9b95-479fbc703456',
    word: '第二次',
    pinyin: 'Yuán',
    audio: 'asd',
    definitions: [
      '1. surname Yuan,',
      '2. the Yuan ot Mongol dynsty',
    ],
  },
  {
    id: 4113,
    doc_id: 'a48cbff2-aa88-42d1-9e1b-7efba931a5f1',
    word: '晚上',
    pinyin: 'Yuán',
    audio: 'asd',
    definitions: [
      '1. surname Yuan,',
      '2. the Yuan ot Mongol dynsty',
    ],
  },
  {
    id: 4643,
    doc_id: '65b954b0-46fc-41b6-b1e7-be160bc0eed6',
    word: '人',
    pinyin: 'Yuán',
    audio: 'asd',
    definitions: [
      '1. surname Yuan,',
      '2. the Yuan ot Mongol dynsty',
    ],
  },
  {
    id: 1643,
    doc_id: '65b954b0-46fc-41b6-b1e7-be160bc0eed6',
    word: '这个年',
    pinyin: 'Yuán',
    audio: 'asd',
    definitions: [
      '1. surname Yuan,',
      '2. the Yuan ot Mongol dynsty',
    ],
  },
]

export const wordDetail: WordDefinition = {
  id: 3424,
  word: '元',
  pinyin: 'yuán',
  audio: 'add',
  definitions: [
    {
      dictionary: 'Dictionary name #1',
      meanings: [
        {
          meaning: '1. (key) element; essential factor.',
          examples: ['多元']
        },
        {
          meaning: '2. unit;component',
          examples: ['单元']
        },
      ]
    },
    {
      dictionary: 'Dictionary name #2',
      meanings: [
        {
          meaning: '1. currency unit (esp. Chinese yuan)',
          examples: ['多元','元始', '元恶']
        },
        {
          meaning: '2. (bound form) first; original; primary',
          examples: ['单元']
        },
      ]
    },
  ]
}

export const phrasesData: PhraseDetail[] = [
  {
    id: 1,
    text: '早晨好｡',
    pinyin: 'zǎo chén hǎo.',
    audio_url: 'abdc',
    translation: 'Good morning.'
  },
  {
    id: 2,
    text: '早晨好｡',
    pinyin: 'zǎo chén hǎo.',
    audio_url: 'abdc',
    translation: 'Good morning.'
  },
  {
    id: 3,
    text: '早晨好｡',
    pinyin: 'zǎo chén hǎo.',
    audio_url: 'abdc',
    translation: 'Good morning.'
  },
  {
    id: 4,
    text: '早晨好｡',
    pinyin: 'zǎo chén hǎo.',
    audio_url: 'abdc',
    translation: 'Good morning.'
  },
  {
    id: 5,
    text: '早晨好｡',
    pinyin: 'zǎo chén hǎo.',
    audio_url: 'abdc',
    translation: 'Good morning.'
  },
]