import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Flex, Input, message, Row, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import DocumentCard from '../../../components/documents/DocumentCard';
import DocumentAddModal from '../../../components/documents/DocumentAddModal';
import DocumentEditModal from '../../../components/documents/DocumentEditModal';
import DocumentDeleteModal from '../../../components/documents/DocumentDeleteModal';
import Spinner from '../../../components/layout/Spinner';
import { DocumentDtoForList } from '../../../data/interfaces/document';
import { editText, getTextByIdForList, getUserTextsForList } from '../../../utils/supabase';
import './index.css';

const { Search } = Input;
const { Title } = Typography;

const DocumentsPage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [openAddTextModal, setOpenAddTextModal] = useState<boolean>(false);
  const [openEditTextModal, setOpenEditTextModal] = useState<boolean>(false);
  const [openDeleteTextModal, setOpenDeleteTextModal] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentDtoForList | null>(null);
  const [documents, setDocuments] = useState<DocumentDtoForList[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    try {
      const response = await getUserTextsForList();
      if (response) {
        setDocuments(response);
      }
    } catch (error) {
      message.error((error as Error).message)
    }
    setLoading(false);
  };

  const onSearch = (value: string) => {
    setSearchText(value);
  }

  const handleFavorite = async (id: string) => {
    try {
      let document = documents.find((d) => d.id === id);
      if (document) {
        await editText({ isFavorite: !document.isFavorite }, document.id);
        fetchData();
      }
    } catch (error) {
      message.error((error as Error).message);
    }
  }

  const handleEdit = (doc?: DocumentDtoForList) => {
    if (doc) {
      setOpenEditTextModal(true);
      setSelectedDocument(doc);
    }
  }

  const callbackForEdit = (payload: any) => {
    const temp: DocumentDtoForList[] = [];
    documents.map((d) => {
      if (d.id === selectedDocument?.id) {
        const newDto : DocumentDtoForList = {
          id: selectedDocument?.id,
          title: payload.title,
          text: payload.text,
          status: payload.status,
          isFavorite: selectedDocument?.isFavorite
        }
        temp.push(newDto);
      } else {
        temp.push(d);
      }
    })
    setDocuments(temp);
  }

  const deleteText = (doc?: DocumentDtoForList) => {
    if (doc) {
      setOpenDeleteTextModal(true);
      setSelectedDocument(doc);
    }
  }

  const callbackForAdd = async (id: string) => {
    fetchData();
    
    if (id) {
      await new Promise(resolve => setTimeout(resolve, 60000));

      let attempts = 0;
      let result = false;
      while (attempts < 120) {
        const response = await getTextByIdForList(id);
        if (response.length > 0 && isDocumentCompleted(response[0])) {
          fetchData();
          result = true;
          break;
        }

        await new Promise(resolve => setTimeout(resolve, 15000));
        attempts++;
      }

      if (!result) {
        message.error('Something went wrong while the new document was being processed!');
      }
    }
  }

  const isDocumentCompleted = (document: DocumentDtoForList): boolean => {
    return Object.values(document.status).some(value => value === 'completed');
  }

  const callbackForDelete = (document: DocumentDtoForList) => {
    setDocuments(documents.filter(d => d.id !== document.id));
  }

  const goToDetail = (id: string) => {
    navigate(`/documents/${id}`);
  }

  return (
    <Row className='w-full'>
      <Flex className='flex bg-white justify-between align-center w-full container'>
        <Title level={4} style={{ marginBottom: '0' }}>Library</Title>
        <Flex gap={24} align='center'>
          <Search placeholder='Search' onSearch={onSearch} className='search-bar' style={{ borderRadius: 2 }} />
          <Button type="primary" icon={<PlusOutlined />} style={{ borderRadius: 0 }} onClick={() => setOpenAddTextModal(true)}>
            New
          </Button>
        </Flex>
      </Flex>

      <Spinner loading={loading}>
        <Row gutter={[{ xs: 16, md: 24 }, { xs: 16, sm: 24 }]} className='container w-full' style={{ marginLeft: 0, marginRight: 0 }}>
          {documents.filter((doc) => doc.title.includes(searchText)).map((doc) => (
            <Col xs={24} sm={12} xl={8} xxl={6} key={doc.id}>
              <DocumentCard document={doc} goToDetail={goToDetail} handleEdit={() => handleEdit(doc)} handleFavorite={handleFavorite} deleteText={() => deleteText(doc)} />
            </Col>
          ))}
        </Row>
      </Spinner>

      <DocumentAddModal open={openAddTextModal} setOpen={setOpenAddTextModal} callback={callbackForAdd} />
      <DocumentEditModal open={openEditTextModal} setOpen={setOpenEditTextModal} document={selectedDocument} callback={callbackForEdit} />
      <DocumentDeleteModal open={openDeleteTextModal} setOpen={setOpenDeleteTextModal} document={selectedDocument} callback={callbackForDelete} />
    </Row>
  );
};

export default DocumentsPage;
