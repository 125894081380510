import React from 'react';
import { Layout as AntLayout } from 'antd';
import HeaderComponent from './Header';
import "./layout.css";

const { Content } = AntLayout;

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <AntLayout style={{ minHeight: '100vh' }}>
      <HeaderComponent />
      <Content
        style={{
          height: '100%',
          minHeight: 280,
          background: '#F0F2F5'
        }}
      >
        {children}
      </Content>
    </AntLayout>
  );
};

export default Layout;
