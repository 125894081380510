import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Image, message, Space, Tabs, TabsProps, Typography } from 'antd';
import { signIn, signUp } from '../../utils/supabase';
import AuthForm from '../../components/auth/AuthForm';
import { RootState } from '../../store';
import LogoIcon from "../../assets/logo_black.svg";
import './auth.css';

const { Title, Text } = Typography;

const AuthPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const [activeKey, setActiveKey] = useState<string>('1');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/documents')
    }
    switch (location.pathname) {
      case '/login':
        setActiveKey('1');
        break;
    
      case '/register':
        setActiveKey('2');
        break;
    
      default:
        break;
    }
  }, [location.pathname, isAuthenticated])

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      activeKey === '1' ? await signIn(values.email, values.password, dispatch) : await signUp(values.email, values.password, dispatch);
      setLoading(false);
      navigate('/documents');
      message.success(activeKey === '1' ? 'You have logged in successfully!' : 'You have been registered successfully!')
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  const onTabChange = (key: string) => {
    setActiveKey(key);
    switch (key) {
      case '1':
        navigate('/login');
        break;

      case '2':
        navigate('/register');
        break;
    
      default:
        break;
    }
  }

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Log In',
      children: (<AuthForm type='login' handleSubmit={handleSubmit} loading={loading} />)
    },
    {
      key: '2',
      label: 'Sign Up',
      children: (<AuthForm type='register' handleSubmit={handleSubmit} loading={loading} />)
    },
  ]

  return (
    <Flex align='center' justify='center' className='flex-col' style={{ height: '100%' }}>
      <Space 
        className='flex-col w-full' 
        align='center' 
        style={{ background: 'white', border: '1px solid #D9D9D9', borderRadius: '.5rem', padding: '1.5rem', gap: '1rem', width: 'fit-content', marginTop: '6rem' }}
      >
        <Flex className='flex-col' align='center' style={{ gap: 12 }}>
          <Flex align='center' style={{ gap: 16 }}>
            <Image src={LogoIcon} alt='logo' width={42} preview={false} />
            <Title style={{ marginBottom: 0 }} level={3}>Bao</Title>
          </Flex>
          <Text className='font-regular' style={{ marginBottom: 0, color: 'gray' }}>Learn Chinese the Simple Way with Us!</Text>
        </Flex>
        <Tabs activeKey={activeKey} items={tabItems} onChange={onTabChange} />
        {/* // TODO(Implement Google, Apple)
        <Flex className='flex-col w-full' style={{ gap: '1rem', borderTop: '1px solid #D9D9D9', paddingTop: '1rem' }}>
          <Button icon={<GoogleCircleFilled style={{ color: '#E6342E' }} />} style={{ borderRadius: '2px', justifyContent: 'start' }} block>
            Continue with Google
          </Button>
          <Button icon={<TwitterCircleFilled style={{ color: '#00ACEE' }} />} style={{ borderRadius: '2px', justifyContent: 'start' }} block>
            Continue with Twitter
          </Button>
        </Flex> */}
      </Space>
      <Flex align='center' justify='center' style={{ marginTop: '1.5rem', gap: '.5rem' }}>
        <Title level={5} style={{ marginBottom: 0 }}>{activeKey === '1' ? 'No account ?' : 'Already have an account ?'}</Title>
        <Link to={activeKey === '1' ? '/register' : '/login'}>{activeKey === '1' ? 'Sign Up' : 'Log In'}</Link>
      </Flex>
      <Text className='font-regular' style={{ marginBottom: 0, color: 'gray', marginTop: 'auto' }}>Copyright ©{new Date().getFullYear()}.</Text>
    </Flex>
  );
};

export default AuthPage;