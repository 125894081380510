import React, { useEffect } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import routes from './routes';
import { useDispatch } from 'react-redux';
import { supabase } from './supabaseClient';
import { setAuth } from './store/authSlice';

const AppRoutes = () => {
  const element = useRoutes(routes);
  return element;
};

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.user) {
        dispatch(setAuth(session.user));
      }
    }

    getSession();
  }, [dispatch])

  return (
    <Router>
      <Layout>
        <AppRoutes />
      </Layout>
    </Router>
  );
};

export default App;
