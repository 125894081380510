import { useEffect, useState } from 'react';
import { Button, Flex, Modal, Typography, Checkbox, Space, message, CollapseProps, Collapse } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import FlashCard from './FlashCard';
import { DocumentDto } from '../../data/interfaces/document';

const { Text } = Typography;

const ExportModal = ({open, setOpen, documents }: {open: boolean, setOpen: (value: boolean) => void, documents: DocumentDto[]}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, React.Key[]>>({});
  const [items, setItems] = useState<CollapseProps['items']>([]);

  useEffect(() => {
    const temp: CollapseProps['items'] = [];
    documents.map((doc) => {
      const words = doc.flashcards;
  
      if (words && words.length > 0) {
        temp.push({
          key: doc.id,
          label: (
            <Flex>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={(selectedRows[doc.id] || []).length === words.length}
                indeterminate={(selectedRows[doc.id] || []).length > 0 && (selectedRows[doc.id] || []).length < words.length}
                onChange={(e: CheckboxChangeEvent) => handleSelectAll(e.target.checked, doc.id)}
                onClick={(e) => e.stopPropagation()}
              />
              {doc.title}
            </Flex>
          ),
          extra: <Text>{words.length} word{words.length > 1 ? 's' : ''}</Text>,
          children: <FlashCard doc={doc} words={words} getRowSelection={getRowSelection} selectable  />
        })
      }

      return doc;
    })
    setItems(temp);
  }, [documents, selectedRows])
  
  const handleSelectAll = (selected: boolean, itemKey: string) => {
    const selectedDoc = documents.find(d => d.id === itemKey);
    if (selectedDoc && selectedDoc.flashcards) {
      const newSelectedRows = { ...selectedRows };
      newSelectedRows[itemKey] = selected ? selectedDoc.flashcards.map((item) => item.word) : [];
      setSelectedRows(newSelectedRows);
    }
  };

  const handleRowSelect = (selectedRowKeys: React.Key[], itemKey: string) => {
    setSelectedRows((prev) => ({
      ...prev,
      [itemKey]: selectedRowKeys
    }))
  };

  const getRowSelection = (itemKey: string) => ({
    onSelectAll: (selected: boolean) => handleSelectAll(selected, itemKey),
    onChange: (selectedRowKeys: React.Key[]) => handleRowSelect(selectedRowKeys, itemKey),
    selectedRowKeys: selectedRows[itemKey] || [],
  });

  const handleSubmit = async () => {
    try {
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      <Modal
        open={open}
        title='Export XML file'
        onCancel={() => setOpen(false)}
        footer={(
          <Flex className='w-full'>
            <Button key='cancel' onClick={() => setOpen(false)} style={{ borderRadius: 2 }}>Cancel</Button>
            <Button key='continue' type='primary' style={{ borderRadius: 2, marginLeft: 'auto' }}>Export</Button>
          </Flex>
        )}
        centered
      >
        <Flex vertical gap={16} style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '1rem 0' }}>
          <Collapse items={items} style={{ borderRadius: 0 }} />
        </Flex>
      </Modal>
    </Space>
  );
};

export default ExportModal;