import { Button, Flex, Typography } from "antd";
import { DeleteOutlined, FileAddOutlined, FileTextOutlined, SoundOutlined } from "@ant-design/icons";
import StatusTag from "./StatusTag";
import { Word } from "../../data/interfaces/document";

const { Title, Text } = Typography;

const successTag = {
  id: 0,
  label: 'Added to Flashcards',
  prop: '',
  status: 'completed'
}

interface RProps {
  word: Word, 
  handleFlashcard: (word: Word, adding: boolean) => void, 
  isFlashcard: number
}

const DictionaryPop = ({word, handleFlashcard, isFlashcard}: RProps) => {
  return (
    <Flex className="flex-col" gap={6} style={{ width: '200px' }}>
      {isFlashcard === 1 && <StatusTag tag={successTag} />}
      <Flex gap={8} align="center">
        <Title level={5} style={{ marginBottom: 0 }}>{word.text}</Title>
        <Button icon={<SoundOutlined />} style={{ border: 'none', boxShadow: 'none', background: 'transparent', width: '1.5rem' }} />
      </Flex>
      <Title level={5} style={{ margin: 0 }}>{word.partOfSpeech}</Title>
      <Title level={5} style={{ margin: 0 }}>{word.pinyin}</Title>
      <Title level={5} style={{ margin: 0 }}>{word.translation}</Title>
      <Flex className="flex-col" gap={8} align="start" style={{ borderTop: '1px solid #DDE1E6', paddingTop: '8px' }}>
        <Flex align="center" gap={8} style={{ cursor: 'pointer' }}>
          <FileTextOutlined />
          <Text>Sentences</Text>
        </Flex>
        {isFlashcard !== 1 ? (
          <Flex align="center" gap={8} style={{ cursor: 'pointer' }} onClick={() => handleFlashcard(word, true)}>
            <FileAddOutlined />
            <Text>Add to flashcards</Text>
          </Flex>
        ) : (
          <Flex align="center" gap={8} style={{ cursor: 'pointer' }} onClick={() => handleFlashcard(word, false)}>
            <DeleteOutlined />
            <Text>Remove from flashcards</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default DictionaryPop;