import { useState } from 'react';
import { Button, Flex, Modal, Space, message } from 'antd';
import FlashCard from '../flashcards/FlashCard';
import { DocumentDto } from '../../data/interfaces/document';

const ExportModal = ({open, setOpen, doc }: {open: boolean, setOpen: (value: boolean) => void, doc: DocumentDto}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

  const getRowSelection = (itemKey: string) => ({
    onChange: (selectedRowKeys: React.Key[]) => setSelectedRows(selectedRowKeys),
    selectedRowKeys: selectedRows,
  });

  const handleSubmit = async () => {
    try {
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      <Modal
        open={open}
        title='Export XML file'
        onCancel={() => setOpen(false)}
        footer={(
          <Flex className='w-full'>
            <Button key='cancel' onClick={() => setOpen(false)} style={{ borderRadius: 2 }}>Cancel</Button>
            <Button key='continue' type='primary' style={{ borderRadius: 2, marginLeft: 'auto' }}>Export</Button>
          </Flex>
        )}
        centered
      >
        <Flex vertical gap={16} style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '1rem 0' }}>
          <FlashCard doc={doc} words={doc.flashcards || []} getRowSelection={getRowSelection} selectable  />
        </Flex>
      </Modal>
    </Space>
  );
};

export default ExportModal;