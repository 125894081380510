import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Flex, Form, Input, message, Space, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { updatePassword } from '../../../utils/supabase';
import '../auth.css';

const { Title, Text } = Typography;

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const accessToken = searchParams.get('access_token');

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      if (!accessToken) {
        message.error('Invalid or expired token.');
      }
      await updatePassword(values.password);
      setLoading(false)
      navigate('/login');
      message.success('Password has been updated successfully!');
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  return (
    <Flex align='center' justify='center' className='flex-col' style={{ height: '100%' }}>
      <Space 
        className='flex-col w-full' 
        align='center' 
        style={{ background: 'white', border: '1px solid #D9D9D9', borderRadius: '.5rem', padding: '1.5rem', gap: '1rem', width: 'fit-content', marginTop: '6rem' }}
      >
        <Title style={{ marginBottom: 0 }} level={4}>Reset your password</Title>
        <Form
          form={form}
          name='reset-password'
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please enter your password!' },
            ]}
            hasFeedback
            style={{ marginBottom: '1rem' }}
          >
            <Input.Password prefix={<LockOutlined />} placeholder='Create New Password' style={{ width: '312px', padding: '8px 12px', borderRadius: '2px' }} />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
            style={{ marginBottom: '1rem' }}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Confirm New Password" style={{ width: '312px', padding: '8px 12px', borderRadius: '2px' }} />
          </Form.Item>
          <Form.Item style={{ margin: '1rem 0 0 0' }}>
            <Button type="primary" htmlType="submit" loading={loading} block style={{ borderRadius: '2px' }}>
              Reset password
            </Button>
          </Form.Item>
        </Form>
      </Space>
      <Text className='font-regular' style={{ marginBottom: 0, color: 'gray', marginTop: 'auto' }}>Copyright ©{new Date().getFullYear()}.</Text>
    </Flex>
  );
};

export default ResetPasswordPage;