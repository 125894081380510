import { Flex, Typography } from "antd";
import { SoundOutlined } from "@ant-design/icons";
import { phrasesData } from "../../data/mockUp";

const { Title, Text } = Typography;

const PhraseTabContent = () => {

  return (
    <Flex vertical className="container">
      {phrasesData.map(p => (
        <Flex key={p.id} vertical className="w-full" gap={4} style={{ borderBottom: '1px solid #DDE1E6', padding: '1rem', cursor: 'pointer' }}>
          <Title level={5} style={{ marginBottom: 0 }}>
            {`${p.text} ${p.pinyin ?? ''} `}
            {p.audio_url && <SoundOutlined style={{ color: '#007AFF', fontSize: '1rem', marginLeft: '.5rem' }} />} 
          </Title>
          <Text>{p.translation}</Text>
        </Flex>
      ))}
    </Flex>
  )
};

export default PhraseTabContent;