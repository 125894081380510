import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex, Radio, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { exercisesData } from '../../../data/mockUp';
import { ExerciseInterface } from '../../../data/interfaces/exercise';

const ExerciseDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { documentId, id } = useParams();
  const [exercise, setExercise] = useState<ExerciseInterface>();

  useEffect(() => {
    if (documentId && id) {
      setExercise(exercisesData.find((e) => e.doc_id.toString() === documentId && e.id.toString() === id))
    }
  }, [documentId, id])

  const handleBackClick = () => {
    navigate(`/documents/${documentId}`)
  }

  return (
    <Row className='w-full'>
      <Flex className='flex bg-white justify-between align-center w-full container'>
        <Title level={4} style={{ marginBottom: '0' }}>Complete Exercises</Title>
        <Button icon={<CloseOutlined />} style={{ border: 'none', boxShadow: 'none' }} onClick={handleBackClick} />
      </Flex>

      <Flex vertical className='w-full container'>
        {[...Array(4)].map((_, idx) => (
          <div key={idx} style={{ marginBottom: '24px' }}>
            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing eli ?</h4>
            <Radio.Group style={{ display: 'flex', flexDirection: 'column' }}>
              <Radio value="A">Label Name</Radio>
              <Radio value="B">Label Name</Radio>
              <Radio value="C">Label Name</Radio>
            </Radio.Group>
          </div>
        ))}
        <Flex 
          justify='space-between' 
          className='w-full container'
        >
          <Button style={{ borderRadius: 0 }}>
            Cancel
          </Button>
          <Button type="primary" disabled style={{ borderRadius: 0 }}>
            Done
          </Button>
        </Flex>
      </Flex>
    </Row>
  );
};

export default ExerciseDetailPage;