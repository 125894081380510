import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Flex, Form, Input, message, Space, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { resetPassword } from '../../../utils/supabase';
import { app_url } from '../../../utils/const';
import '../auth.css';

const { Title, Text } = Typography;

const ForgotPasswordPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      await resetPassword(values.email, app_url + '/reset-password');
      setLoading(false)
      message.success('Password reset email sent. Check your inbox');
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  return (
    <Flex align='center' justify='center' className='flex-col' style={{ height: '100%' }}>
      <Space 
        className='flex-col w-full' 
        align='center' 
        style={{ background: 'white', border: '1px solid #D9D9D9', borderRadius: '.5rem', padding: '1.5rem', gap: '1rem', width: 'fit-content', marginTop: '6rem' }}
      >
        <Title style={{ marginBottom: 0 }} level={4}>Forgot password?</Title>
        <Form
          form={form}
          name='forgot-password'
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="email"
            rules={[
              { type: 'email', message: 'The input is not valid email!' },
              { required: true, message: 'Please enter your email!' },
            ]}
            style={{ marginBottom: '1rem' }}
          >
            <Input prefix={<UserOutlined />} placeholder="Email.address@gmail.com" style={{ width: '312px', padding: '8px 12px', borderRadius: '2px' }} />
          </Form.Item>
          <Form.Item style={{ margin: '1rem 0 0 0' }}>
            <Button type="primary" htmlType="submit" loading={loading} block style={{ borderRadius: '2px' }}>
              Send reset password link
            </Button>
          </Form.Item>
        </Form>
      </Space>
      <Flex align='center' justify='center' style={{ marginTop: '1.5rem', gap: '.5rem' }}>
        <Link to='/login'>Return to Log In</Link>
      </Flex>
      <Text className='font-regular' style={{ marginBottom: 0, color: 'gray', marginTop: 'auto' }}>Copyright ©{new Date().getFullYear()}.</Text>
    </Flex>
  );
};

export default ForgotPasswordPage;