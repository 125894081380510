import { Flex, message, Table, TableColumnsType } from "antd";
import { BookFilled, BookOutlined } from "@ant-design/icons";
import { DataTypeForWordTable, DocumentDto, Word } from "../../data/interfaces/document";
import { FlashcardDto } from "../../data/interfaces/flashcard";
import { editText } from "../../utils/supabase";
import { getPartOfSpeech } from "../../utils/document";

const WordTabContent = ({doc, callback}: {doc?: DocumentDto, callback: (payload: DocumentDto) => void}) => {
  const extractWords = (doc?: DocumentDto) => {
    const wordsList: Word[] = [];
  
    if (doc) {
      doc.json_structure?.content?.paragraphs
        ?.flatMap(paragraph => paragraph.sentences || [])
        .flatMap(sentence => sentence.words || [])
        .filter(word => word.partOfSpeech && word.partOfSpeech !== 'punctuation')
        .forEach(word => {
            if (!wordsList.find(w => w.text === word.text)) {
              wordsList.push(word);
            }
          });
    }
    
    return wordsList;
  }

  const isFlashcard = (word: DataTypeForWordTable) => {
    return doc?.flashcards?.find(f => f.word === word.text) ? true : false;
  }

  const handleFlashcard = async (record: DataTypeForWordTable, adding: boolean) => {
    try {
      if (doc) {
        if (adding) {
          const newFlashcard: FlashcardDto = {
            word: record.text,
            pinyin: record.pinyin,
            audio_url: record.audio_url,
            part_of_speech: record.partOfSpeech,
            short_definitions: record.translation ? [record.translation] : []
          }
    
          doc.flashcards?.push(newFlashcard);
        } else {
          doc.flashcards = doc.flashcards?.filter(f => f.word !== record.text)
        }

        await editText(doc, doc.id);
        callback(doc);
      }
    } catch (error) {
      message.error((error as Error).message);
    }
  }

  const columns: TableColumnsType<DataTypeForWordTable> = [
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => isFlashcard(record) ? (
        <BookFilled onClick={() => handleFlashcard(record, false)} />
      ) : (
        <BookOutlined onClick={() => handleFlashcard(record, true)} />
      ),
    },
    {
      title: 'Index',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Word',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Pinyin',
      dataIndex: 'pinyin',
      key: 'pinyin',
    },
    {
      title: 'PartOfSpeech',
      dataIndex: 'partOfSpeech',
      key: 'partOfSpeech',
    },
    {
      title: 'Translation',
      dataIndex: 'translation',
      key: 'translation',
      render: (text) => (
        <span style={{ wordBreak: 'break-word' }}>{text}</span>
      ),
    }
  ]

  const data = extractWords(doc).map((w, index) => {
    return {
      key: index,
      index: index + 1,
      text: w.text,
      pinyin: w.pinyin,
      audio_url: w.audio_url,
      partOfSpeech: w.partOfSpeech ? getPartOfSpeech(w.partOfSpeech) : '',
      translation: w.translation
    }
  })

  return (
    <Flex gap={16} vertical className="container">
      <Table<DataTypeForWordTable> 
        columns={columns} 
        dataSource={data} 
        showHeader={false} 
        pagination={false} 
        bordered
        style={{ width: '100%', tableLayout: 'fixed' }}
        size='small' 
      />
    </Flex>
  )
};

export default WordTabContent;